import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, filter, take } from 'rxjs';

import { environment } from 'src/environments/environment';

import { ConnectivityService } from './services/connectivity.service';

export interface Maintenance {
  id: string;
  hasMaintenance: boolean;
}

@Injectable()
export class SystemService {
  private defaultHeaders: any;
  private baseUrl: string;

  // tslint:disable-next-line: variable-name
  private _maintenance = new BehaviorSubject<boolean>(false);
  readonly maintenance = this._maintenance.asObservable();

  constructor(private http: HttpClient) {
    this.baseUrl = environment.systemMonitor.baseUrl;
    this.defaultHeaders = {
      [environment.systemMonitor.apiKeyName]:
        environment.systemMonitor.apiKeyValue,
    };
  }

  setMaintenanceStatus(value: boolean) {
    this._maintenance.next(value);
  }

  async onApplicationLoad(connectivityService: ConnectivityService) {
    connectivityService.isOnlineObservable
      .pipe(
        filter((isOnline) => isOnline),
        take(1)
      )
      .subscribe(async () => {
        const data = await this.getMaintenanceStatus();
        this.setMaintenanceStatus(data.hasMaintenance);
      });
  }

  getMaintenanceStatus(): Promise<Maintenance> {
    const url = `${this.baseUrl}/maintenance/scheduling`;
    const httpOptions = { headers: new HttpHeaders(this.defaultHeaders) };
    return this.http
      .get(url, httpOptions)
      .toPromise()
      .then((data: Maintenance) => {
        return data;
      });
  }
}
