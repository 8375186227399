import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';
import { KeycloakService } from 'keycloak-angular';

import { SharedModule } from 'src/app/shared/shared.module';

import { NoWifiIconComponent } from 'src/app/shared/no-wifi-icon/no-wifi-icon.component';

import { ConnectivityService } from '../../services/connectivity.service';

@Component({
  selector: 'app-no-connection-screen',
  standalone: true,
  imports: [SharedModule, NoWifiIconComponent],
  templateUrl: './no-connection-screen.component.html',
  styleUrl: './no-connection-screen.component.scss',
})
export class NoConnectionScreenComponent implements OnInit {
  isMobileUser: boolean;
  isUserOnline: boolean;

  isKeycloakInstanceInvalid: boolean;

  previousUrl: string | null = null;
  desiredUrl: string | null = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private deviceDetectorService: DeviceDetectorService,
    private connectivityService: ConnectivityService,
    private keycloak: KeycloakService
  ) {}

  ngOnInit() {
    this.isMobileUser = this.deviceDetectorService.isMobile();

    this.activatedRoute.queryParams.subscribe((params) => {
      this.previousUrl = params['previous-url'] || null;
      this.desiredUrl = params['desired-url'] || null;
    });

    this.connectivityService.isOnlineObservable.subscribe((isUserOnline) => {
      this.isUserOnline = isUserOnline;

      this.isKeycloakInstanceInvalid =
        this.isUserOnline && !this.keycloak.getKeycloakInstance();
    });
  }

  handleTryAgain() {
    this.router.navigateByUrl(this.desiredUrl, { replaceUrl: true });
  }

  handleGoBack() {
    this.router.navigateByUrl(this.previousUrl, { replaceUrl: true });
  }

  goToHome() {
    this.router.navigate(['/home'], { replaceUrl: true });
  }

  handleReload() {
    window.location.reload();
  }
}
