import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from './auth.service';
import { ConnectivityService } from '../services/connectivity.service';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root',
})
export class ConnectivityGuard {
  constructor(
    private authService: AuthService,
    private connectivityService: ConnectivityService,
    private toastr: ToastrService,
    protected router: Router
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.connectivityService.isOnline) {
      return true;
    }

    if (!route.data['supportsOfflineUsage']) {
      this.handleNoConnectionRedirect(state);
      return false;
    }

    const routeRequiresAuthentication =
      route.routeConfig?.canActivate?.includes(AuthGuard);

    if (!routeRequiresAuthentication) {
      return true;
    }

    const isUserAuthenticated = await this.authService.isLoggedIn();
    const isKeycloakUser = await this.authService.isKeycloakUser();

    if (!isUserAuthenticated || isKeycloakUser) {
      this.handleNoConnectionRedirect(state);

      if (!isKeycloakUser) {
        this.toastr.error(
          'You need to be authenticated to access this page. Please go online and re-authenticate.'
        );
      }

      return false;
    }

    return true;
  }

  private handleNoConnectionRedirect(state: RouterStateSnapshot) {
    if (!this.router.url.startsWith('/no-connection')) {
      this.router.navigate(['/no-connection'], {
        queryParams: {
          'previous-url': this.router.url !== '/' ? this.router.url : null,
          'desired-url': state.url,
        },
      });
    } else {
      this.toastr.warning("You're still offline!");
    }
  }
}
