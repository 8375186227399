import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MenuOption } from '../menu/menu.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private defaultHeaders: any;
  option: MenuOption;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-ontrax-identity': `${this.authService.user.etendoUsername || this.authService.user.username};${this.authService.user.currentRoleAcronym}`,
    };

    this.option = MenuOption.Reports;

    this.authService.user$.subscribe((user) => {
      if (user) {
        this.defaultHeaders['x-ontrax-identity'] =
          `${this.authService.user.etendoUsername || this.authService.user.username};${this.authService.user.currentRoleAcronym}`;
      }
    });
  }

  getDashboardTerminalReports(): Promise<any> {
    const path = `/get-embed-url/${environment.reports.reportIds.terminalReports}`;
    const httpOptions = { headers: this.defaultHeaders };
    return API.get('ReportsAPI', path, httpOptions);
  }

  getDashboardWashMetrics(): Promise<any> {
    const path = `/get-embed-url/${environment.reports.reportIds.customerWashMetrics}`;
    const httpOptions = { headers: this.defaultHeaders };
    return API.get('ReportsAPI', path, httpOptions);
  }
  getDashboardEhsWasteManagement(): Promise<any> {
    const path = `/get-embed-url/${environment.reports.reportIds.ehsWasteManagement}`;
    const httpOptions = { headers: this.defaultHeaders };
    return API.get('ReportsAPI', path, httpOptions);
  }
  getDashboardLeanDailyManagement(): Promise<any> {
    const path = `/get-embed-url/${environment.reports.reportIds.leanDailyManagement}`;
    const httpOptions = { headers: this.defaultHeaders };
    return API.get('ReportsAPI', path, httpOptions);
  }

  getDashboardById(dashboardId): Promise<any> {
    const path = `/get-embed-url/${dashboardId}`;
    const httpOptions = { headers: this.defaultHeaders };
    return API.get('ReportsAPI', path, httpOptions);
  }
}
