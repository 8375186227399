import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InternalNcrCreationComponent } from './internal-ncr-creation/internal-ncr-creation.component';
import { NcrListComponent } from './ncr-list/ncr-list.component';
import { NcrEditComponent } from './ncr-edit/ncr-edit.component';
import { PermissionGuard } from 'src/app/core/auth/permission.guard';

const routes: Routes = [
  {
    path: 'edit/:id',
    component: NcrEditComponent,
    data: {
      supportsOfflineUsage: false,
    },
  },
  {
    path: 'create',
    component: InternalNcrCreationComponent,
    data: {
      supportsOfflineUsage: false,
    },
  },
  {
    path: 'list/:option',
    component: NcrListComponent,
    canActivate: [PermissionGuard],
    data: {
      supportsOfflineUsage: false,
    },
  },
  { path: '**', redirectTo: 'list/completed' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NonConformityReportRoutingModule {}
