import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import {
  OwlMomentDateTimeModule,
  OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
} from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { DisplayFieldComponent } from './display-field/display-field.component';
import { LabelInputComponent } from './label-input/label-input.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileItemComponent } from './file-upload/file-item/file-item.component';
import { ToggleComponent } from './toggle/toggle.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { BooleanPipe } from './boolean-pipe/boolean.pipe';
import { DatePipe } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { DialogConfirmationComponent } from './dialog-confirmation/dialog-confirmation.component';
import { DialogConfirmationService } from './dialog-confirmation/dialog-confirmation.service';
import { LoadingPipe } from './loading-pipe/loading.pipe';
import { ReplacePipe } from './replace-pipe/replace.pipe';
import { MatRadioModule } from '@angular/material/radio';
import { FilterInputComponent } from './filter-input/filter-input.component';
import { DiscretLoaderComponent } from './discret-loader/discret-loader.component';
import { QualaButtonComponent } from './quala-button/quala-button';
import { NgSelectModule, NgSelectConfig } from '@ng-select/ng-select';
import { FeatureToggleDirective } from './featuretoggle/feature-toggle.directive';
import { NineNineNinePlusPipe } from './nineninenineplus/nineninenineplus.pipe';
import { ExpandedImageComponent } from './expanded-image/expanded-image.component';
import { HighlightErrorsPipe } from './highlight-errors-pipe/highlight-errors.pipe';
import { FormatServiceTypePipe } from './format-service-type/format-service-type.pipe';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CheckboxListComponent } from './checkbox-list/checkbox-list.component';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { EllipsisLoaderComponent } from './ellipsis-loader/ellipsis-loader.component';
import { FileUploadThumbComponent } from './file-upload-thumb/file-upload-thumb.component';
import { FileItemThumbComponent } from './file-upload-thumb/file-item-thumb/file-item-thumb.component';
import { RadialTimePickerComponent } from './radial-time-picker/radial-time-picker.component';
import { CalendarDatePickerComponent } from './calendar-date-picker/calendar-date-picker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NewsFeedComponent } from '../dashboard/home/ontrax-feed/news-feed/news-feed.component';
import { NewsItemComponent } from '../dashboard/home/ontrax-feed/news-feed/news-item/news-item.component';
import { ConfinedSpaceEntryComponent } from './confined-space-entry/confined-space-entry.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormFeedbackComponent } from './form-feedback/form-feedback.component';
import { InViewportModule, DestroyableDirective } from 'ng-in-viewport';
import { EtendoIconComponent } from './open-bravo-icon/etendo-icon.component';
import { CloseByEscDirective } from './close-by-esc/close-by-esc.directive';
import { SchneiderCompletionCompletedIconComponent } from './schneider-completion/completed-icon/completed-icon.component';
import { SchneiderCompletionErrorIconComponent } from './schneider-completion/error-icon/error-icon.component';
import { SchneiderCompletionProgressIconComponent } from './schneider-completion/progress-icon/progress-icon.component';
import { SchneiderCompletionPendingIconComponent } from './schneider-completion/pending-icon/pending-icon.component';
import { SpinnerLoaderComponent } from './spinner-loader/spinner-loader.component';
import { SideMenuToggleDirective } from './side-menu-toggle/side-menu-toggle.directive';
import { LastContainedGroupComponent } from './last-contained-group/last-contained-group.component';
import { BrandLoaderComponent } from './brand-loader/brand-loader.component';

@NgModule({
  declarations: [
    CheckboxListComponent,
    DisplayFieldComponent,
    LabelInputComponent,
    FileUploadComponent,
    FileUploadThumbComponent,
    FileItemComponent,
    FileItemThumbComponent,
    ToggleComponent,
    DateTimePickerComponent,
    BooleanPipe,
    LoadingPipe,
    TimePickerComponent,
    DialogConfirmationComponent,
    FilterInputComponent,
    DiscretLoaderComponent,
    QualaButtonComponent,
    FeatureToggleDirective,
    NineNineNinePlusPipe,
    ExpandedImageComponent,
    HighlightErrorsPipe,
    FormatServiceTypePipe,
    MultiSelectComponent,
    ReplacePipe,
    CheckboxListComponent,
    EllipsisLoaderComponent,
    SpinnerLoaderComponent,
    BrandLoaderComponent,
    RadialTimePickerComponent,
    CalendarDatePickerComponent,
    NewsFeedComponent,
    NewsItemComponent,
    EtendoIconComponent,
    ConfinedSpaceEntryComponent,
    FormFeedbackComponent,
    CloseByEscDirective,
    SchneiderCompletionCompletedIconComponent,
    SchneiderCompletionErrorIconComponent,
    SchneiderCompletionProgressIconComponent,
    SchneiderCompletionPendingIconComponent,
    SideMenuToggleDirective,
    LastContainedGroupComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    OverlayModule,
    FontAwesomeModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    MatTooltipModule,
    MatDialogModule,
    InfiniteScrollModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatListModule,
    CurrencyMaskModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatRadioModule,
    InViewportModule,
  ],
  exports: [
    CheckboxListComponent,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatTooltipModule,
    MatToolbarModule,
    MatIconModule,
    DisplayFieldComponent,
    LabelInputComponent,
    FileUploadComponent,
    FileUploadThumbComponent,
    OverlayModule,
    FontAwesomeModule,
    ToggleComponent,
    DateTimePickerComponent,
    TimePickerComponent,
    BooleanPipe,
    LoadingPipe,
    ScrollingModule,
    DialogConfirmationComponent,
    MatRadioModule,
    FilterInputComponent,
    InfiniteScrollModule,
    DiscretLoaderComponent,
    QualaButtonComponent,
    FormsModule,
    NgSelectModule,
    FeatureToggleDirective,
    NineNineNinePlusPipe,
    ExpandedImageComponent,
    HighlightErrorsPipe,
    FormatServiceTypePipe,
    MultiSelectComponent,
    ReplacePipe,
    MatTabsModule,
    CurrencyMaskModule,
    EllipsisLoaderComponent,
    SpinnerLoaderComponent,
    BrandLoaderComponent,
    RadialTimePickerComponent,
    CalendarDatePickerComponent,
    NewsFeedComponent,
    EtendoIconComponent,
    ConfinedSpaceEntryComponent,
    FormFeedbackComponent,
    CloseByEscDirective,
    SchneiderCompletionCompletedIconComponent,
    SchneiderCompletionErrorIconComponent,
    SchneiderCompletionProgressIconComponent,
    SchneiderCompletionPendingIconComponent,
    SideMenuToggleDirective,
    LastContainedGroupComponent,
  ],
  providers: [
    DatePipe,
    DialogConfirmationService,
    {
      provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
    NgSelectConfig,
    DestroyableDirective,
  ],
})
export class SharedModule {}
