import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ScheduleComponent } from './schedule.component';
import { PermissionGuard } from 'src/app/core/auth/permission.guard';

const routes: Routes = [
  {
    path: '',
    component: ScheduleComponent,
    canActivate: [PermissionGuard],
    data: {
      supportsOfflineUsage: false,
    },
  },
  { path: '**', redirectTo: 'schedule' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ScheduleRoutingModule {}
