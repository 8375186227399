import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MainNavComponent } from 'src/app/global/main-nav/main-nav.component';
import { NcrIconComponent } from 'src/app/shared/ncr-icon/ncr-icon.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { OntraxLogoCompleteV1Component } from 'src/app/shared/ontrax-logo-complete-v1/ontrax-logo-complete-v1.component';
import { UserType } from '../menu/user-type.enum';
import { allTiles } from './tiles.config';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ConnectivityService } from 'src/app/core/services/connectivity.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    MainNavComponent,
    NcrIconComponent,
    SharedModule,
    OntraxLogoCompleteV1Component,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  tiles: any[];
  allRoutesDatas: any[] = [];

  constructor(
    private authService: AuthService,
    private connectivityService: ConnectivityService,
    private router: Router
  ) {
    this.getAllRouteDatas();
  }

  ngOnInit(): void {
    const userType = this.authService.getUserType();

    if (userType == UserType.External) {
      const reportsTile = allTiles.find((t) => t.label == 'Reports');
      reportsTile.link = '/dashboard/wash-metrics';
    }

    this.tiles = allTiles.filter(
      (t) =>
        t.visibleByUserType == UserType.AllUserTypes ||
        t.visibleByUserType == userType
    );

    this.connectivityService.isOnlineObservable.subscribe((isUserOnline) => {
      allTiles.forEach((tile) => {
        if (tile.disabled) {
          return;
        }

        const tilePath = this.getTilePath(tile.link);

        const routeData = this.allRoutesDatas.find((route) => {
          return route.path === tilePath;
        });

        if (routeData?.data?.['supportsOfflineUsage']) {
          return;
        }

        tile.offlineDisabled = !isUserOnline;
      });
    });
  }

  getAllRouteDatas() {
    const extractRouteData = (route) => {
      const { path, data, children } = route;
      const routeData = { path, data };

      const childrenData = children ? children.flatMap(extractRouteData) : [];

      return [routeData, ...childrenData];
    };

    this.allRoutesDatas = this.router.config.flatMap(extractRouteData);
  }

  getTilePath(tileLink) {
    const tilePathList = tileLink.split('/').filter(Boolean);

    if (tilePathList.length > 1) {
      return tilePathList.slice(1).join('/');
    } else {
      return tilePathList.slice(1);
    }
  }
}
