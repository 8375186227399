import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { API } from '@aws-amplify/api';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FileItemService {
  private templateDownloadUrl: string;
  private defaultHeaders: any;

  constructor(private authService: AuthService) {
    this.templateDownloadUrl = environment.bulkTemplate.downloadUrl;

    if (!!this.authService.user) {
      this.defaultHeaders = {
        'Content-Type': 'application/json',
        'x-ontrax-identity': `${this.authService.user.etendoUsername || this.authService.user.username};${this.authService.user.currentRoleAcronym}`,
      };
    }

    this.authService.user$.subscribe((user) => {
      if (user)
        this.defaultHeaders = {
          'Content-Type': 'application/json',
          'x-ontrax-identity': `${user.etendoUsername || user.username};${user.currentRoleAcronym}`,
        };
    });
  }

  downloadFileService(fileName: string, originalName: string) {
    const path = '/file-download?file=' + fileName;
    const httpOptions = {
      headers: this.defaultHeaders,
    };
    API.get('PortalAPI', path, httpOptions).then((data) => {
      saveAs(data, originalName);
    });
  }

  downloadBulkTemplate() {
    const url = this.templateDownloadUrl;
    window.open(url);
  }

  getFileUrl(fileName: string) {
    const path = '/file-download?file=' + fileName;
    const httpOptions = {
      headers: this.defaultHeaders,
    };
    return API.get('PortalAPI', path, httpOptions);
  }
}
