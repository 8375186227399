import { NcrIconComponent } from 'src/app/shared/ncr-icon/ncr-icon.component';
import { UserType } from '../menu/user-type.enum';

export const allTiles = [
  {
    label: 'OnTrax Feed',
    icon: 'newspaper',
    link: '/dashboard/feed',
    customClasses: 'ontrax-feed',
    visibleByUserType: UserType.AllUserTypes,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Ordering',
    icon: 'truck',
    link: '/dashboard/wash-list',
    visibleByUserType: UserType.AllUserTypes,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Scheduling',
    icon: 'calendar-check',
    link: '/dashboard/schedule',
    visibleByUserType: UserType.Internal,
    hidden: false,
    disabled: true,
    offlineDisabled: false,
  },
  {
    label: 'Shift Logs',
    icon: 'address-book',
    link: '/dashboard/shift-logs',
    visibleByUserType: UserType.Internal,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'NCR',
    customIconComponent: NcrIconComponent,
    link: '/dashboard/non-conformity-report',
    visibleByUserType: UserType.AllUserTypes,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Reports',
    icon: 'chart-column',
    link: '/dashboard/reports',
    visibleByUserType: UserType.AllUserTypes,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Heat Management',
    icon: 'temperature-arrow-up',
    link: '/dashboard/heat-management',
    visibleByUserType: UserType.Internal,
    hidden: true,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Yard Check',
    icon: 'walking',
    link: '/dashboard/yard-check',
    visibleByUserType: UserType.Internal,
    hidden: true,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Bulk Upload',
    icon: 'cloud-arrow-up',
    link: '/dashboard/bulk-upload/download-template',
    visibleByUserType: UserType.AllUserTypes,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
];
