import { Component, OnInit, OnDestroy } from '@angular/core';
import { HostListener } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocalStorageService } from '../../core/localstorage.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/auth/auth.service';
import { User } from '../../core/model/user.model';
import { Router } from '@angular/router';
import { RolePickerService } from 'src/app/core/services/role-picker.service';

@Component({
  selector: 'app-role-picker',
  templateUrl: './role-picker.component.html',
  styleUrls: ['./role-picker.component.scss'],
})
export class RolePickerComponent implements OnInit, OnDestroy {
  authenticateRoleForm: UntypedFormGroup;
  userRoleId$: Subscription;
  userRoleId: UntypedFormControl;
  private user: User;
  private wasInside = false;

  public userRoles: Array<any>;

  rolePickerIsEnabled: boolean;
  public userCurrentRole: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private rolePickerService: RolePickerService
  ) {
    this.rolePickerIsEnabled = false;
  }

  ngOnInit() {
    if (this.authService.user) {
      this.userRoles = this.getUserRoleList(this.authService.user);
      this.userCurrentRole = this.getRoleDisplayName(
        this.authService.user.currentRole,
        this.authService.user.roles
      );
    }
    this.createFormControls();
    this.createFormGroup();
  }

  ngOnDestroy() {
    this.userRoleId$.unsubscribe();
  }

  createFormGroup() {
    this.authenticateRoleForm = new UntypedFormGroup({
      userRoleId: this.userRoleId,
    });
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.rolePickerIsEnabled = false;
    }
    this.wasInside = false;
  }

  @HostListener('document:keydown.escape')
  onEscapeKey() {
    this.rolePickerIsEnabled = false;
  }

  public pickRole() {
    this.rolePickerIsEnabled = !this.rolePickerIsEnabled;
    this.userRoleId.setValue(null);
  }

  private formatDisplayName(
    terminalName: string,
    terminalNumber: string,
    roleName: string
  ) {
    return `${terminalName.split('-')[1]} - ${terminalNumber.split(/B|E/)[1]} - ${roleName.split('.').pop()}`;
  }

  private getRoleDisplayName(
    currentRole: string,
    userRoles: Array<any>
  ): string {
    const currentRoleTerminal = currentRole.split('#T=')[1];
    let roleName: string = null;

    userRoles.find((item) => {
      if (item.name) {
        const terminal = item.terminals.find(
          (terminal) => terminal.terminalNumber === currentRoleTerminal
        );
        if (terminal) {
          roleName = this.formatDisplayName(
            terminal.terminalName,
            terminal.terminalNumber,
            item.name
          );
          return true;
        }
      } else if (item[currentRole]) {
        roleName = item[currentRole];
        return true;
      }
      return false;
    });

    if (roleName) {
      return roleName;
    }

    return 'Choose the terminal / role';
  }

  createFormControls() {
    this.userRoleId = new UntypedFormControl('');

    this.userRoleId$ = this.userRoleId.valueChanges.subscribe((role) => {
      if (!role) {
        return;
      }

      if (role === this.authService.user.currentRole) {
        this.rolePickerIsEnabled = false;
        return;
      }

      this.rolePickerService.triggerRoleChange();

      this.authService.changeRole(role).then((user) => {
        const currentUrl = this.router.url;
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            const decodedUrl = decodeURIComponent(currentUrl);
            this.router.navigateByUrl(decodedUrl);
          });
        this.userCurrentRole = this.getRoleDisplayName(
          user.currentRole,
          user.roles
        );
        this.rolePickerService.resetRoleChange();
      });
      this.rolePickerIsEnabled = false;
    });
  }

  private getUserRoleList(user: User) {
    const roleList = [];
    user.roles.forEach((item) => {
      if (item.name) {
        item.terminals.forEach((terminal) => {
          roleList.push({
            id: `${item.name}#T=${terminal.terminalNumber}`,
            name: this.formatDisplayName(
              terminal.terminalName,
              terminal.terminalNumber,
              item.name
            ),
          });
        });
      } else {
        roleList.push({
          id: Object.keys(item)[0],
          name: Object.values(item)[0],
        });
      }
    });

    roleList.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return roleList;
  }
}
