import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { uniqBy } from 'lodash';

import { Warehouse } from '../model/warehouse.model';
import { APIService } from '../API.service';
import { PublicAPIService } from '../PublicAPI.service';

interface DataStore {
  warehouses: Warehouse[];
  allWarehouses: Warehouse[];
}

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  public dataStore: DataStore = {
    warehouses: [],
    allWarehouses: [],
  };

  private _warehouses = new BehaviorSubject<Warehouse[]>([]);
  readonly warehouses$ = this._warehouses.asObservable();

  private _allWarehouses = new BehaviorSubject<Warehouse[]>([]);
  readonly allWarehouses$ = this._allWarehouses.asObservable();

  constructor(private apiService: APIService) {}

  private mapWarehouseItem(item: any): Warehouse {
    return {
      id: item.id,
      searchKey: item.searchKey,
      active: item.active,
      terminalName: item.terminalName,
      terminalNumber: item.terminalNumber,
      terminalNameDisplay: item.terminalNameDisplay,
      terminalNumberDisplay: item.terminalNumberDisplay,
      terminalLaunch: item.terminalLaunch,
      operationalRegion: item.operationalRegion,
      linesOfBusiness: item.linesOfBusiness,
      country: item.country,
      continent: item.continent,
    };
  }

  private async loadWarehouses(
    fetchData: (nextToken?: string) => Promise<any>,
    dataStoreArray: (typeof this.dataStore)[keyof DataStore],
    dataStoreBehaviorSubject: BehaviorSubject<Warehouse[]>,
    nextToken?: string
  ) {
    const data = await fetchData(nextToken);

    if (!data || !data.items) {
      return;
    }

    const processedData = data.items.map(this.mapWarehouseItem);
    const uniqueData = uniqBy([...dataStoreArray, ...processedData], 'id');

    dataStoreArray.length = 0;
    dataStoreArray.push(...uniqueData);
    dataStoreBehaviorSubject.next(dataStoreArray);

    if (data.nextToken) {
      await this.loadWarehouses(
        fetchData,
        dataStoreArray,
        dataStoreBehaviorSubject,
        data.nextToken
      );
    }
  }

  async loadActiveWarehouses() {
    await this.loadWarehouses(
      async (nextToken?: string) =>
        this.apiService.GetActiveWarehouses(nextToken),
      this.dataStore.warehouses,
      this._warehouses
    );
  }

  async loadAllWarehouses(
    apiService: APIService | PublicAPIService = this.apiService
  ) {
    await this.loadWarehouses(
      async (nextToken?: string) => apiService.GetAllWarehouses(nextToken),
      this.dataStore.allWarehouses,
      this._allWarehouses
    );
  }
}
