// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quala-button {
  display: flex;
  padding: 10px 24px;
  align-items: center;
  justify-content: center;
}
.quala-button .text-button {
  margin: 0px;
  height: -moz-fit-content;
  height: fit-content;
  overflow-wrap: break-word;
}
.quala-button.action-button.square-button {
  border-radius: 5px;
  font-weight: 500;
  height: 32px;
  white-space: nowrap;
}
.quala-button.action-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

:host ::ng-deep app-ellipsis-loader .lds-ellipsis div {
  top: 16px !important;
}

@media (max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape) {
  .quala-button .text-button {
    max-width: 85%;
    text-wrap: wrap;
  }
}
@media (orientation: portrait) {
  .quala-button {
    font-size: 0.75rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/quala-button/quala-button.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EACE,WAAA;EACA,wBAAA;EAAA,mBAAA;EACA,yBAAA;AADJ;AAKI;EACE,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;AAHN;AAMI;EACE,mBAAA;EACA,YAAA;AAJN;;AAYI;EACE,oBAAA;AATN;;ACtBI;EDoCF;IACE,cAAA;IACA,eAAA;EAVF;AACF;AAaA;EACE;IACE,kBAAA;EAXF;AACF","sourcesContent":["@import '../../../mixins.scss';\n\n.quala-button {\n  display: flex;\n  padding: 10px 24px;\n  align-items: center;\n  justify-content: center;\n\n  .text-button {\n    margin: 0px;\n    height: fit-content;\n    overflow-wrap: break-word;\n  }\n\n  &.action-button {\n    &.square-button {\n      border-radius: 5px;\n      font-weight: 500;\n      height: 32px;\n      white-space: nowrap;\n    }\n\n    &:disabled {\n      cursor: not-allowed;\n      opacity: 0.7;\n    }\n  }\n}\n\n// Loading animation\n:host ::ng-deep {\n  app-ellipsis-loader {\n    .lds-ellipsis div {\n      top: 16px !important;\n    }\n  }\n}\n@include breakpoint(mobile) {\n  .quala-button .text-button {\n    max-width: 85%;\n    text-wrap: wrap;\n  }\n}\n\n@media (orientation: portrait) {\n  .quala-button {\n    font-size: 0.75rem;\n  }\n}\n","@mixin breakpoint($point) {\n  @if $point == mobile {\n    @media (max-width: 599.98px) and (orientation: portrait),\n      (max-width: 959.98px) and (orientation: landscape) {\n      @content;\n    }\n  } @else if $point == tablet {\n    @media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait),\n      (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape) {\n      @content;\n    }\n  } @else if $point == desktop {\n    @media (min-width: 840px) and (orientation: portrait),\n      (min-width: 1280px) and (orientation: landscape) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
