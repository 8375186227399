import { inject, NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

import { AuthGuard } from './core/auth/auth.guard';
import { SystemMaintenanceGuard } from './system-maintenance/system-maintenance.guard';

import { environment } from 'src/environments/environment';

import { OfferStatus } from './exterior-wash-offer-response/exterior-wash-offer-response.model';

import { DashboardComponent } from './dashboard/dashboard.component';
import { SystemMaintenanceComponent } from './system-maintenance/system-maintenance.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginErrorComponent } from './login-error/login-error.component';
import { ExternalNcrCreationComponent } from './external-ncr-creation/external-ncr-creation.component';
import { ExteriorWashOfferResponseComponent } from './exterior-wash-offer-response/exterior-wash-offer-response.component';
import { LoginComponent } from './login/login.component';
import { CognitoCallbackComponent } from './core/auth/cognito-callback/cognito-callback.component';
import { IframeAuthHandlerComponent } from './iframe-auth-handler/iframe-auth-handler.component';
import { HomeComponent } from './dashboard/home/home.component';
import { homeRedirectGuard } from './guards/home-redirect.guard';
import { NoConnectionScreenComponent } from './core/auth/no-connection-screen/no-connection-screen.component';
import { ConnectivityGuard } from './core/auth/connectivity.guard';

const routes: Routes = [
  {
    path: SystemMaintenanceComponent.route,
    canActivate: [ConnectivityGuard, SystemMaintenanceGuard],
    component: SystemMaintenanceComponent,
    data: {
      supportsOfflineUsage: false,
    },
  },
  {
    path: DashboardComponent.route,
    canActivate: [ConnectivityGuard, AuthGuard],
    canActivateChild: [ConnectivityGuard, AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'home',
    canActivate: [ConnectivityGuard, AuthGuard, homeRedirectGuard],
    component: HomeComponent,
    data: {
      supportsOfflineUsage: true,
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [ConnectivityGuard],
    data: {
      supportsOfflineUsage: false,
    },
  },
  {
    path: 'no-connection',
    component: NoConnectionScreenComponent,
    data: {
      supportsOfflineUsage: true,
    },
  },
  {
    path: 'cognito-callback',
    component: CognitoCallbackComponent,
  },
  {
    path: 'external-ncr',
    component: ExternalNcrCreationComponent,
    canActivate: [ConnectivityGuard],
    data: {
      supportsOfflineUsage: false,
    },
  },
  {
    path: 'exterior-wash-accept',
    component: ExteriorWashOfferResponseComponent,
    canActivate: [ConnectivityGuard],
    data: {
      offerResponseStatus: OfferStatus.ACCEPT,
      supportsOfflineUsage: false,
    },
  },
  {
    path: 'exterior-wash-decline',
    component: ExteriorWashOfferResponseComponent,
    canActivate: [ConnectivityGuard],
    data: {
      offerResponseStatus: OfferStatus.DECLINE,
      supportsOfflineUsage: false,
    },
  },
  {
    path: 'login-depot',
    component: LoginComponent,
    canActivate: [
      ConnectivityGuard,
      async () => {
        const router = inject(Router);

        const redirectToNewLogin = environment.redirectToNewLoginPage;

        if (redirectToNewLogin) {
          router.navigate(['/']);
          return false;
        }

        return true;
      },
    ],
    data: {
      shouldUseGetUserInfo: true,
      supportsOfflineUsage: false,
    },
  },
  {
    path: 'login-depot-tests',
    component: LoginComponent,
    canActivate: [
      async () => {
        const router = inject(Router);

        if (environment.production) {
          router.navigate(['/']);
          return false;
        }

        return true;
      },
    ],
  },
  {
    path: 'login-error',
    component: LoginErrorComponent,
    canActivate: [ConnectivityGuard],
    data: {
      supportsOfflineUsage: false,
    },
  },
  {
    path: 'iframe-auth-handler',
    component: IframeAuthHandlerComponent,
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
