import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { get } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AuthService } from '../core/auth/auth.service';
import {
  MenuConfig,
  MenuOption,
  OnTraxSubSystemPlatformsMenus,
} from './menu/menu.model';
import { WashListService } from './wash-list/wash-list.service';
import { APIService } from '../core/API.service';
import { SystemService } from '../core/system.service';
import { User } from '../core/model/user.model';
import { LocalStorageService } from '../core/localstorage.service';
import { BusinessPartnerService } from '../core/services/business-partner.service';
import { WarehouseService } from '../core/services/warehouse.service';
import { MasterAccountService } from '../core/services/master-account.service';
import { GtmUtilsService } from '../shared/gtm-utils.service';
import { requestListSection } from './menu/menu.config';
import { UserType } from './menu/user-type.enum';
import { MenuService } from './menu/menu.service';
import { FunctionRegistryService } from '../shared/function-registry/function-registry.service';
import { SideMenuToggle } from './dashboard.model';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('slideMenu', [
      state(
        'true',
        style({
          transform: 'none',
        })
      ),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class DashboardComponent implements OnInit {
  static route = 'dashboard';
  public isVisible: boolean;
  private isTabActive = true;
  subSystemName: string;
  imageFolderPath = '../../assets/images/';
  defaultLandscapeViewLogoPath = `${this.imageFolderPath}onTrax-logo.png`;
  landscapeViewLogoPath: string;
  menuConfig: MenuConfig;
  isMobile: boolean;
  private user: User;
  userName: string;
  isPortrait: boolean;
  showMobileMenu: boolean; // #TODO remove feature flag after tiles version
  showMainNav: boolean;
  showRolePicker: boolean;
  roleCountIntervalId = null;

  constructor(
    private businessPartnerService: BusinessPartnerService,
    private washListService: WashListService,
    private warehouseService: WarehouseService,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private appSyncService: APIService,
    private systemService: SystemService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private masterAccountService: MasterAccountService,
    private gtmUtilsService: GtmUtilsService,
    private menuService: MenuService,
    private functionRegistryService: FunctionRegistryService,
    private deviceDetector: DeviceDetectorService,
    library: FaIconLibrary
  ) {
    library.addIconPacks(fas);
    this.authService.user$.subscribe((user) => {
      if (user) this.refetchDynamicContent();
    });
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe((result) => {
      this.washListService.changeBreakpointFlag(!result.matches);
      this.isMobile = !result.matches;
    });

    this.breakpointObserver
      .observe([
        Breakpoints.WebPortrait,
        Breakpoints.HandsetPortrait,
        Breakpoints.Handset,
      ])
      .subscribe((result) => {
        this.isPortrait = result.matches;
      });

    this.landscapeViewLogoPath = this.defaultLandscapeViewLogoPath;

    this.showMainNav = this.deviceDetector.isMobile();
  }

  refetchDynamicContent(): void {
    if (this.localStorageService) {
      this.setUserData();
      this.loadSchneiderCompletionOption();
      this.configureMenu();
      this.loadWashBillToTerminal();
      if (this.roleCountIntervalId) {
        clearInterval(this.roleCountIntervalId);
        this.roleCountIntervalId = null;
      }
      this.initializeUserRoleItemCounts();
    }
  }

  loadSchneiderCompletionOption() {
    if (this.authService.hasQualaWorkerRole()) {
      this.gtmUtilsService
        .getGtmConfig('showSchneiderPortalCompletions')
        .then((showSection) => {
          if (!showSection) {
            return;
          }

          this.menuConfig?.sections?.find((section) => {
            if (section.label === 'OnTrax Requests') {
              const schneiderOption = requestListSection.options.find(
                (option) =>
                  option.value === MenuOption.SchneiderPortalCompletions
              );
              if (
                schneiderOption &&
                this.authService.getUserType() === UserType.Internal
              ) {
                section.options.push(schneiderOption);
              }
            }
          });
        });
    }
  }

  ngOnInit() {
    this.listenToUrlChanges();
    this.showRolePicker = this.menuService.hasRolePicker(this.router.url);
    this.router.events.subscribe(() => {
      this.showRolePicker = this.menuService.hasRolePicker(this.router.url);
    });
    this.businessPartnerService.loadBusinessPartners().catch((error) => {
      throw error;
    });
    this.setUserData();
    this.configureMenu();
    this.loadSchneiderCompletionOption();
    this.functionRegistryService.setFunction(
      SideMenuToggle.SIDE_MENU,
      this.toggleMenu.bind(this)
    );
    this.functionRegistryService.setFunction(
      SideMenuToggle.SIDE_MENU_IS_VISIBLE,
      this.getIsVisible.bind(this)
    );
    this.businessPartnerService.loadBusinessPartners().catch((error) => {
      throw error;
    });
    this.masterAccountService.getMasterCustomer().catch((error) => {
      throw error;
    });
    this.washListService.loadServiceType().catch((error) => {
      throw error;
    });
    this.washListService.loadContainerType().catch((error) => {
      throw error;
    });
    this.washListService.loadProductContent().catch((error) => {
      throw error;
    });
    this.warehouseService.loadActiveWarehouses().catch((error) => {
      throw error;
    });
    this.warehouseService.loadAllWarehouses().catch((error) => {
      throw error;
    });
    this.washListService.currentBreakpoint.subscribe(
      (flag) => (this.isVisible = flag)
    );
    this.washListService.loadExteriorWashProducts().catch((error) => {
      throw error;
    });

    this.washListService.getReasonsOfChange().catch((error) => {
      throw error;
    });

    this.loadWashBillToTerminal();

    this.appSyncService.OnMaintenanceChangesListener.subscribe((event: any) => {
      const maintenance = get(
        event,
        ['value', 'data', 'onMaintenanceChanges'],
        {}
      );
      this.systemService.setMaintenanceStatus(maintenance.hasMaintenance);
      if (maintenance.hasMaintenance) {
        this.router.navigate(['/system-maintenance']);
      }
    });

    if (this.user.customerBlocking) {
      this.toastr.warning(
        '',
        'Account on hold. Requests can be entered but will not be scheduled. Please contact your sales representative.'
      );
    }

    window.onblur = () => {
      this.isTabActive = false;
    };

    window.onfocus = () => {
      this.isTabActive = true;
    };

    this.initializeUserRoleItemCounts();
  }

  public toggleMenu(): void {
    this.isVisible = !this.isVisible;
  }

  public getIsVisible(): boolean {
    return this.isVisible;
  }

  get hasQualaRole() {
    return this.authService.hasQualaWorkerRole();
  }

  getAllWashItemsCountForUserRole() {
    if (this.hasQualaRole) {
      this.washListService.refreshCount([
        MenuOption.Draft,
        MenuOption.NewWashRequests,
        MenuOption.Pending,
        MenuOption.Accepted,
        MenuOption.NeedsAction,
        MenuOption.InProgress,
        MenuOption.Hold,
        MenuOption.Completed,
        MenuOption.Canceled,
        MenuOption.Rejected,
        MenuOption.CreditHold,
        MenuOption.Expired,
        MenuOption.SchneiderPortalCompletions,
      ]);
    } else {
      this.washListService.refreshCount([
        MenuOption.Draft,
        MenuOption.Submitted,
        MenuOption.NeedsAction,
        MenuOption.Accepted,
        MenuOption.Pending,
        MenuOption.InProgress,
        MenuOption.Completed,
        MenuOption.Canceled,
        MenuOption.Rejected,
      ]);
    }
  }

  getImportantItemsCountForQualaRole() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.NewWashRequests,
      MenuOption.Pending,
      MenuOption.NeedsAction,
    ]);
  }

  getNormalItemsCountForQualaRole() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Accepted,
      MenuOption.InProgress,
      MenuOption.Hold,
      MenuOption.CreditHold,
    ]);
  }

  getImportantItemsCountForDispatcher() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Submitted,
      MenuOption.Accepted,
    ]);
  }

  getNormalItemsCountForDispatcher() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.NeedsAction,
      MenuOption.Pending,
      MenuOption.InProgress,
    ]);
  }

  getLowPriorityItemsCount() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Draft,
      MenuOption.Completed,
      MenuOption.Canceled,
      MenuOption.Rejected,
    ]);
  }

  listenToUrlChanges() {
    const subSystemInfos = [
      {
        urls: ['wash-list'],
        logoPath: `${this.imageFolderPath}ontrax-requests-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.Requests,
      },
      {
        urls: ['non-conformity-report'],
        logoPath: `${this.imageFolderPath}ontrax-ncr-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.Ncr,
      },
      {
        urls: [MenuOption.Schedule],
        logoPath: `${this.imageFolderPath}ontrax-scheduling-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.Scheduling,
      },
      {
        urls: [
          MenuOption.Reports,
          MenuOption.WashMetrics,
          MenuOption.LeanDailyManagementReport,
          MenuOption.TerminalScorecardDepot,
          MenuOption.TerminalScorecardIndustrial,
          MenuOption.TerminalScorecardRail,
          MenuOption.TerminalScorecardWash,
          MenuOption.EhsWasteManagement,
        ],
        logoPath: `${this.imageFolderPath}ontrax-reports-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.Reports,
      },
      {
        urls: [MenuOption.ShiftLogs],
        logoPath: `${this.imageFolderPath}ontrax-shift-logs-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.ShiftLogs,
      },
      {
        urls: ['bulk-upload'],
        logoPath: `${this.imageFolderPath}ontrax-bulk-upload-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.BulkUpload,
      },
    ];

    this.router.events.subscribe((event) => {
      for (const subSystem of subSystemInfos) {
        for (const url of subSystem.urls) {
          if (this.router.url.includes(url)) {
            this.landscapeViewLogoPath = subSystem.logoPath;
            this.subSystemName = subSystem.name;
            return;
          }
        }
      }
      this.landscapeViewLogoPath = this.defaultLandscapeViewLogoPath;
      this.subSystemName = OnTraxSubSystemPlatformsMenus.Default;

      if (
        event instanceof NavigationEnd &&
        (this.isPortrait || this.deviceDetector.isMobile())
      ) {
        this.isVisible = false;
      }
    });
  }

  private initializeUserRoleItemCounts() {
    this.authService.isAuthorized().then((isAuthenticated) => {
      if (isAuthenticated) {
        const oneMinuteInMilliseconds = 60000;
        this.getAllWashItemsCountForUserRole();
        this.roleCountIntervalId = setInterval(() => {
          this.getAllWashItemsCountForUserRole();
        }, oneMinuteInMilliseconds * 3);
      }
    });
  }

  private loadWashBillToTerminal(): void {
    const customerId = this.authService.getBusinessPartnerId();
    if (customerId) this.washListService.loadWashBillToTerminal(customerId);
  }

  private configureMenu(): void {
    this.menuConfig = this.menuService.getMenuConfig();
    this.gtmUtilsService
      .getGtmConfig('showMobileMenu')
      .then((showMobileMenu) => {
        this.showMobileMenu = showMobileMenu;
      });
  }

  private setUserData(): void {
    this.user = this.localStorageService.getUser();
    this.userName = this.user.username;
  }
}
